<template>
  <CommonLayout>
    <div class="profile">
      <div class="profile__body">
        <div class="profile__col">
          <div class="profile-photo">
            <img
              v-if="user.photo"
              class="profile-photo__pic"
              :src="userPhoto"
              width="268"
              height="268"
              alt=""
            />
            <img
              v-else-if="user.isCaptain"
              class="profile-photo__pic"
              src="@/assets/img/pic2/avatar-captain.svg"
              width="268"
              height="268"
              alt=""
            />
            <img
              v-else
              class="profile-photo__pic"
              src="@/assets/img/pic2/avatar-default.svg"
              width="268"
              height="268"
              alt=""
            />
          </div>
          <div class="profile-photo__foot">
            <!-- <span v-if="user.photo">
              <button @click="onDeletePhoto" class="profile-photo__button">
                удалить
              </button>
              или&nbsp;
            </span> -->
            <button
              v-if="!isOtherProfile"
              @click="$modal.show('upload-photo')"
              class="profile-photo__button"
            >
              Изменить аватар
            </button>
            <div class="profile-list">
              <div class="profile-list__item">
                <div class="profile-list__button" @click="toAchivements">
                  Достижения
                </div>
                <div class="profile-list__body">
                  <div
                    v-if="user.top3Achievements && user.top3Achievements.length"
                    class="profile-achievement-list"
                  >
                    <button
                      v-for="(item, index) in user.top3Achievements"
                      :key="index"
                      class="profile-achievement-list__item"
                      :style="{
                        'background-image': `url('/static/achieves/${item.icon}')`,
                      }"
                      @click="toAchivements"
                    >
                      <Tooltip
                        type="profile"
                        :msg="`
                        <p><strong>${item.title}</strong></p>
                        <p>${item.description}</p>`"
                      />
                    </button>
                  </div>
                  <div v-else class="profile-achievement-list">
                    <div class="profile-achievement-list__item"></div>
                  </div>
                </div>
              </div>
              <div class="profile-list__item">
                <!-- <div
                  class="profile-list__button"
                  @click="
                    $modal.show('stats', {
                      data: {
                        userId: user.id,
                        title: 'GPB-coins',
                        userCoin: getUserCoinTotalRatings(),
                      },
                    })
                  "
                >
                  GPB-coins
                </div> -->
                <div class="profile-list__button" @click="getUserCoinProfile">
                  GPB-coins
                </div>
                <div class="profile-list__body">
                  <div class="profile-summary-coins">
                    <img
                      src="@/assets/img/pic2/coin.svg"
                      width="32"
                      height="32"
                      alt=""
                    />{{ user.coinsAcquired | priceFilter }}
                  </div>
                </div>
              </div>
              <div class="profile-list__item">
                <router-link
                  v-if="user.id"
                  :to="{
                    name: 'gratitude',
                    params: { userID: user.id },
                    query: { profile: 1 },
                  }"
                  class="profile-list__button"
                  >Благодарности</router-link
                >
                <div class="profile-list__body">
                  <div class="profile-summary-gratitudes">
                    <img
                      src="@/assets/img/pics/heart.svg"
                      width="35"
                      height="30"
                      alt=""
                    />{{
                      (user.gratitudeState &&
                        user.gratitudeState.totalReceived) ||
                      0
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="profile-photo__gratitude profile-photo__gratitude-profile"
            >
              <router-link
                v-if="user.id"
                :to="{
                  name: 'gratitude',
                  params: { userID: user.id },
                  query: { profile: !this.isOtherProfile },
                }"
                class="button-default"
                ><span>Отправить</span></router-link
              >
            </div>
          </div>
        </div>
        <div class="profile__col">
          <h1 class="profile__title">Личный кабинет</h1>
          <div v-if="user.id" class="profile-info">
            <div class="profile-info__item profile-info__item_size_double">
              <div class="profile-info__label">Дефендер</div>
              <div class="profile-info__text">{{ user.fullName }}</div>
            </div>
            <div class="profile-info__item">
              <div class="profile-info__label">Ник</div>
              <div class="profile-info__text">
                <div class="nickname">
                  <form
                    v-if="editNickname"
                    class="nickname__edit"
                    @submit.prevent="updateNickname"
                  >
                    <div class="input-wraper">
                      <input
                        v-model.trim="newNickname"
                        class="nickname__edit-input text__input"
                        type="text"
                        placeholder="Введите ваш ник"
                      />
                      <button class="nickname__edit-button"></button>
                      <span
                        class="nickname__edit-cancel"
                        @click="hideEditNickname()"
                        >Отмена</span
                      >
                    </div>
                  </form>
                  <div v-else class="nickname__view">
                    <div
                      class="nickname__view-text limit"
                      :title="user.nickname"
                    >
                      {{ user.nickname ? user.nickname : user.fullName }}
                    </div>
                    <button
                      v-if="!isOtherProfile"
                      type="button"
                      class="nickname__view-button"
                      @click="showEditNickname"
                    ></button>
                  </div>
                </div>
              </div>
            </div>
            <div class="profile-info__item">
              <div class="profile-info__label">Эл. почта</div>
              <div class="profile-info__text">{{ user.eMail }}</div>
            </div>
            <div class="profile-info__item">
              <div class="profile-info__label">Специализация</div>
              <div class="profile-info__text">
                {{ user.userGroup | functionalGroupFilter }}
              </div>
            </div>
            <div class="profile-info__item">
              <div class="profile-info__label">Дата начала игры</div>
              <div class="profile-info__text">
                {{ user.firstQuestDate | formatDate }}
              </div>
              <div class="profile-info__label" style="margin-bottom: -10px">
                Неделя: <strong>{{ currentWeek }}</strong
                ><br />
                Планета: <strong>{{ currentIsland.title }}</strong>
              </div>
            </div>
            <div v-if="user.teamName" class="profile-info__item">
              <div class="profile-info__label">Команда</div>
              <div class="profile-info__text">
                <router-link
                  :to="{ name: 'team', params: { teamID: user.teamId } }"
                  >{{ user.teamName }}</router-link
                >
              </div>
            </div>
            <div v-if="!isOtherProfile" class="profile-info__item">
              <div class="profile-info__label">&nbsp;</div>
              <div class="profile-info__text">
                <router-link :to="{ name: 'settingsNotify' }"
                  >Настроить письма</router-link
                >
              </div>
            </div>
            <!-- <div v-if="user.isCaptain" class="profile-info__item">
              <div class="captain">
                <div class="captain__title">Вы – капитан</div>
                <div class="captain__link">
                  <a
                    href="/static/docs/captain_instruction.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Инструкция капитана</a
                  >
                </div>
              </div>
            </div> -->
          </div>
          <div v-if="user.id" class="profile-kpi">
            <div class="profile-kpi__inner">
              <div class="profile-kpi__title">Основные KPI</div>
              <div v-if="mainKpiValues.length" class="profile-kpi-graphics">
                <!-- <div class="profile-kpi-graphics__item">
                  <div class="profile-dount">
                    <div class="profile-dount__inner">
                      <div class="profile-dount__name">СВР</div>
                      <div class="profile-dount__value">0,087%</div>
                      <button
                        class="profile-dount__button"
                        @click="
                          $modal.show('statsRating', {
                            data: {
                              title: 'СВР',
                            },
                          })
                        "
                      >
                        Статистика
                      </button>
                    </div>
                  </div>
                  <div class="profile-kpi-graphics__date">01.03-10.04</div>
                </div>
                <div class="profile-kpi-graphics__item">
                  <div class="profile-dount">
                    <div class="profile-dount__inner">
                      <div class="profile-dount__name">ПВНУТР</div>
                      <div class="profile-dount__value">0,05</div>
                      <button
                        class="profile-dount__button"
                        @click="
                          $modal.show('statsRating', {
                            data: {
                              title: 'ПВНУТР',
                            },
                          })
                        "
                      >
                        Статистика
                      </button>
                    </div>
                  </div>
                  <div class="profile-kpi-graphics__date">01.03-10.04</div>
                </div>
                <div class="profile-kpi-graphics__item">
                  <div class="profile-dount">
                    <div class="profile-dount__inner">
                      <div class="profile-dount__name">КК</div>
                      <div
                        class="profile-dount__value profile-dount__value_size_small"
                      >
                        желтый
                      </div>
                      <button
                        class="profile-dount__button"
                        @click="
                          $modal.show('statsRating', {
                            data: {
                              title: 'КК',
                            },
                          })
                        "
                      >
                        Статистика
                      </button>
                    </div>
                  </div>
                  <div class="profile-kpi-graphics__date">01.03-10.04</div>
                </div> -->
                <div
                  v-for="(item, index) in mainKpiValues"
                  :key="index"
                  class="profile-kpi-graphics__item"
                >
                  <div class="profile-dount">
                    <div class="profile-dount__inner">
                      <div class="profile-dount__name">
                        {{ item.kpiShortName }}
                      </div>
                      <div
                        class="profile-dount__value profile-dount__value_size_small"
                        :class="{
                          green: item.score === 5,
                          yellow: item.score === 4,
                          pink: item.score === 3,
                          red: item.score === 2,
                        }"
                      >
                        <span v-if="kpiKK.includes(item.kpi)">
                          <template v-if="item.score === 5"> зеленый </template>
                          <template v-else-if="item.score === 4">
                            желтый
                          </template>
                          <template v-else-if="item.score === 3">
                            розовый
                          </template>
                          <template v-else-if="item.score === 2">
                            красный
                          </template>
                          <template v-else> - </template></span
                        ><span v-else>{{ item.absoluteValue }}%</span>
                      </div>
                      <button
                        class="profile-dount__button"
                        @click="
                          $modal.show('statsRating', {
                            data: {
                              title: item.kpiShortName,
                              kpiId: item.kpi,
                            },
                          })
                        "
                      >
                        Статистика
                      </button>
                    </div>
                  </div>
                  <div class="profile-kpi-graphics__date">
                    {{ item.week | formatTimeRange2 }}
                  </div>
                </div>
              </div>
              <div
                v-else
                class="profile-stats-empty profile-stats-empty_place_top"
              >
                Значения появятся как только<br />вы найдете в капсулах
                задания<br />на дополнительные KPI
              </div>
              <div class="profile-kpi__title">Дополнительные KPI</div>
              <div
                v-if="otherKpiValues.length"
                class="profile-stats profile-kpi-stats"
              >
                <div class="profile-stats__inner">
                  <div
                    v-for="(item, index) in otherKpiValues"
                    :key="index"
                    class="profile-stats__row"
                  >
                    <div class="profile-stats__item">
                      <div class="profile-stats__name">
                        {{ item.kpiName }}
                      </div>
                      <div class="profile-stats__line"><span></span></div>
                    </div>
                    <div class="profile-stats__item">
                      <div class="profile-stats__date">
                        <!-- {{ item.importDate | formatDate2 }} -->
                        {{ item.week | formatTimeRange }}
                      </div>
                    </div>
                    <div class="profile-stats__item">
                      <div class="profile-stats__line"><span></span></div>
                      <div class="profile-stats__value">
                        {{ item.value }} {{ item.measure }}
                        <!-- {{ item.kpiName === "ТД" ? "шт" : "%" }} -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="profile-stats-empty">
                Значения появятся по результатам выполнения заданий
              </div>
            </div>
          </div>
          <!-- <div v-if="user.id" class="profile-graphics">
            <div class="profile-graphics__inner">
              <div class="profile-graphics__item">
                <ChartDount
                  :desc="dount1[0].kpiShortName"
                  :value="dount1[0].absoluteValue"
                  unit="%"
                  color="purple"
                >
                  <button
                    @click="
                      $modal.show('stats', {
                        filter: true,
                        data: {
                          kpiId: dount1[0].kpi,
                          userId: user.id,
                          title: dount1[0].kpiShortName,
                          value: dount1[0].absoluteValue,
                          users: getUsersKPI(dount1[0].kpi),
                          usersTotal: getUsersTotalKPI(dount1[0].kpi),
                          unit: '%',
                        },
                      })
                    "
                  >
                    Статистика
                  </button>
                </ChartDount>
              </div>
              <div class="profile-graphics__item">
                <ChartDount
                  :desc="dount2[0].kpiShortName"
                  :value="dount2[0].absoluteValue"
                  unit="%"
                  color="green"
                >
                  <button
                    @click="
                      $modal.show('stats', {
                        filter: true,
                        data: {
                          kpiId: dount2[0].kpi,
                          userId: user.id,
                          title: dount2[0].kpiShortName,
                          value: dount2[0].absoluteValue,
                          users: getUsersKPI(dount2[0].kpi),
                          usersTotal: getUsersTotalKPI(dount2[0].kpi),
                          unit: '%',
                        },
                      })
                    "
                  >
                    Статистика
                  </button>
                </ChartDount>
              </div>
              <div class="profile-graphics__item">
                <ChartDount
                  :desc="dount3[0].kpiShortName"
                  :value="dount3[0].absoluteValue"
                  unit="%"
                  color="pink"
                >
                  <button
                    @click="
                      $modal.show('stats', {
                        filter: true,
                        data: {
                          kpiId: dount3[0].kpi,
                          userId: user.id,
                          title: dount3[0].kpiShortName,
                          value: dount3[0].absoluteValue,
                          users: getUsersKPI(dount3[0].kpi),
                          usersTotal: getUsersTotalKPI(dount3[0].kpi),
                          unit: '%',
                        },
                      })
                    "
                  >
                    Статистика
                  </button>
                </ChartDount>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <!-- <div class="profile__foot">
        <div class="container__status">
          <div class="header header__status">Статусы задач</div>
          <div v-if="tasks.length">
            <div class="row row_head">
              <div class="col col__status-date text__status-table">Дата</div>
              <div class="col col__status-task text__status-table">
                Название задачи
              </div>
              <div class="col col__status-status text__status-table">
                Статус
              </div>
              <div class="col col__status-gpb-coin text__status-table">
                Начислено {{ COINS }}
              </div>
            </div>
            <template v-for="task in tasks">
              <div
                :key="`table-task-divider-${task.id}`"
                class="divider__table"
              >
                <img src="@/assets/img/pic2/divider-tasks.svg" alt="" />
              </div>
              <div
                @click="openQuest(task)"
                :key="`table-task-${task.id}`"
                class="row"
                :class="{ hover: task.state < 2 }"
              >
                <div class="col col__status-date text__status-table-date">
                  {{ task.acceptedDate | formatDate }}
                </div>
                <div class="col col__status-task text text__status-table-task">
                  {{ task.title }}
                </div>
                <div class="col col__status-status text__status-table">
                  {{ task.state | functionalTaskStatus }}
                </div>
                <div
                  class="col col__status-gpb-coin text text__status-table-task"
                >
                  <div class="coins">
                    <img
                      width="15"
                      height="15"
                      src="@/assets/img/pic2/coin.svg"
                      alt=""
                    />
                    {{ task.state === 5 ? 0 : task.receivedCoins }}
                  </div>
                </div>
              </div>
            </template>
            <div class="clearfix"></div>
          </div>
          <div v-else>Пусто</div>
        </div>
      </div> -->
    </div>

    <!-- <div class="container">
      <div class="row">
        <div class="row">
          <div class="col col__name">
            <div class="header_small">ФИО</div>
            <div class="text">{{ user.fullName }}</div>
          </div>
          <div class="col col__contacts">
            <div class="header_small">Эл. почта</div>
            <div class="text">{{ user.eMail }}</div>
          </div>
        </div>
        <div class="row">
          <div class="col col__name">
            <div class="header_small">Ник</div>
          </div>
          <div class="col col__contacts">
            <div class="header_small">Специализация</div>
            <div class="text">
              {{ user.userGroup | functionalGroupFilter }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col col__name">
            <div class="text-raiting-coins">
              <div class="text-raiting-coins__personal">
                Персональный<br />рейтинг в игре
              </div>
              <div class="text-raiting-coins__team">Рейтинг<br />команды</div>
            </div>
            <div class="text-raiting-coins">
              <div class="field-score">
                <span>{{ user.personalRating | priceFilter }}</span>
              </div>
              <div class="field-score">
                <span>{{ user.teamRating | priceFilter }}</span>
              </div>
            </div>
            <div class="divider">
              <img src="@/assets/img/pic/divider-profile.svg" alt="divider" />
            </div>
          </div>
        </div>

        <div class="col col__coins">
          <div class="header_small">Дата начала игры</div>
          <div class="text">{{ user.gameStartDate | formatDate }}</div>
          <div class="text-raiting-coins">
            <div class="text-raiting-coins__get">{{ COINS }} получено</div>
            <div class="text-raiting-coins__check">{{ COINS }} на проверке</div>
            <div>{{ COINS }} благодарностей</div>
          </div>
          <div class="text-raiting-coins">
            <div class="field-score">
              <span class="coin">
                <img src="@/assets/img/pic/coin.png" alt="" />
              </span>
              <span>{{ user.coinsAcquired | priceFilter }}</span>
            </div>
            <div class="field-score">
              <span class="coin">
                <img src="@/assets/img/pic/coin.png" alt="" />
              </span>
              <span>{{ user.coinsOnModeration | priceFilter }}</span>
            </div>
            <div class="field-score">
              <span class="coin">
                <img src="@/assets/img/pic/coin.png" alt="" />
              </span>
              <span>{{ user.canSendCoins | priceFilter }}</span>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="modals">
      <ModalTaskQuestion v-on:close="closeModalTask" />
      <ModalTaskTest v-on:close="closeModalTask" />
      <ModalTaskKPI v-on:close="closeModalTask" />
      <ModalTaskBusiness v-on:close="closeModalTask" />
    </div> -->

    <!-- Статусы задач END  -->
  </CommonLayout>
</template>

<script>
import CommonLayout from "@/layout/CommonLayout.vue";
import { DeleteProfilePhoto } from "@/api/Player";
// import { GetPlayerQuestsStates } from "@/api/PlayerQuests";
import { GetQuestByID } from "@/api/PlayerQuests";
import { mapGetters, mapActions, mapMutations } from "vuex";
// import ModalTaskKPI from "@/components/ModalTaskKPI.vue";
// import ModalTaskBusiness from "@/components/ModalTaskBusiness.vue";
// import ModalTaskQuestion from "@/components/ModalTaskQuestion.vue";
// import ModalTaskTest from "@/components/ModalTaskTest.vue";
// import ChartDount from "@/components/ChartDount.vue";
import { getBaseURL, mathRoundToDecimal } from "@/utils";
import { islandList } from "@/data/game";
import { GetOtherPlayerProfile } from "@/api/Player";
import Tooltip from "@/components/Tooltip.vue";

const kpiKK = [
  "ACCR_MON_QualityAssurance",
  "RKK_IKAR_CUST_QualityAssurance",
  "RKK_IKAR_ON_QualityAssurance",
  "RKK_IKAR_IU_CUST_NONST_QualityAssurance",
  "RKK_IKAR_IU_ON_NONST_QualityAssurance",
  "UDL_A1_FA_QualityAssurance",
];

export default {
  components: {
    CommonLayout,
    Tooltip,
    // ModalTaskKPI,
    // ModalTaskBusiness,
    // ModalTaskQuestion,
    // ModalTaskTest,
    // ChartDount,
  },
  computed: {
    ...mapGetters([
      "getProfile",
      "getLastKpiValues",
      "getLast3BusinessKpi",
      "getCoinWeeklyRatings",
      "getCoinTotalRatings",
      "getGame",
      "getProfileCurrentIsland",
      "getUsersCoinsProfileStatus",
      "getUsersCoinsProfile",
      "getUsersCoinsProfileOverall",
    ]),
    userPhoto() {
      return getBaseURL() + this.user.photo;
    },
    dount1() {
      return this.getLastKpiValues.filter((e) => e.kpiShortName === "СВР");
    },
    dount2() {
      return this.getLastKpiValues.filter(
        (e) => e.kpiShortName === "ПВНУТР" || e.kpiShortName === "В"
      );
    },
    dount3() {
      return this.getLastKpiValues.filter((e) => e.kpiShortName === "КК");
    },
    otherStats() {
      return this.getLastKpiValues.filter(
        (e) =>
          e.kpiShortName !== "СВР" &&
          e.kpiShortName !== "ПВНУТР" &&
          e.kpiShortName !== "КК" &&
          e.kpiShortName !== "В"
      );
    },
    mainKpiValues() {
      if (this.isOtherProfile) {
        return this.user.lastKpiValues || [];
      }
      return this.getLastKpiValues || [];
    },
    otherKpiValues() {
      if (this.isOtherProfile) {
        return this.user.last3BusinessKpi || [];
      }
      return this.getProfile.last3BusinessKpi || [];
    },
    currentWeek() {
      let week = this.getGame.currentWeek;
      if (week >= 35) {
        week = 36;
      } else {
        week = week + 1;
      }
      return week;
    },
    currentIsland() {
      const currentIsland = this.getProfileCurrentIsland.islandIdx;
      let data = {};

      if (currentIsland !== undefined) {
        islandList.forEach((island, index) => {
          if (index === currentIsland) {
            data.name = island.name;
            data.title = island.title;
          }
        });
      }

      return data;
    },
    user() {
      return this.isOtherProfile ? this.otherUser : this.getProfile;
    },
  },
  data() {
    return {
      newNickname: "",
      editNickname: false,
      tasks: [],
      isOtherProfile: false,
      otherUser: {},
      kpiKK,
    };
  },
  watch: {
    $route() {
      this.init();
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions(["USER_NICKNAME", "USERS_COINS_PROFILE_FETCH"]),
    ...mapMutations(["USER_DELETE_PHOTO"]),
    async init() {
      const { userID } = this.$route.params;
      if (userID) {
        this.isOtherProfile = true;
        this.otherUser = await this.fetchData(userID);
      } else {
        this.isOtherProfile = false;
      }
    },
    // fetchData() {
    //   GetPlayerQuestsStates().then((resp) => {
    //     this.tasks = resp.data;
    //   });
    // },
    async fetchData(userID) {
      const resp = await GetOtherPlayerProfile({ playerId: userID });
      let {
        lastKpiValues,
        last3BusinessKpi,
        coinWeeklyRatings,
        coinTotalRatings,
      } = resp.data;

      if (lastKpiValues.length) {
        lastKpiValues.forEach((e) => {
          e.absoluteValue = mathRoundToDecimal(e.absoluteValue);
        });
      }

      // if (kpiWeeklyRatings.length) {
      //   kpiWeeklyRatings.forEach((item) => {
      //     if (item.positions.length) {
      //       item.positions.forEach((e) => {
      //         e.value = mathRoundToDecimal(e.value);
      //       });

      //       item.positions = item.positions.sort((a, b) => {
      //         return a.position - b.position;
      //       });
      //     }
      //   });
      // }

      // if (kpiTotalRatings.length) {
      //   kpiTotalRatings.forEach((item) => {
      //     if (item.positions.length) {
      //       item.positions.forEach((e) => {
      //         e.value = mathRoundToDecimal(e.value);
      //       });
      //       item.positions = item.positions.sort((a, b) => {
      //         return a.position - b.position;
      //       });
      //     }
      //   });
      // }

      if (last3BusinessKpi) {
        last3BusinessKpi.forEach((e) => {
          e.value = mathRoundToDecimal(e.value);
        });
      }

      if (coinWeeklyRatings && coinWeeklyRatings.length) {
        coinWeeklyRatings = coinWeeklyRatings.sort((a, b) => {
          return a.position - b.position;
        });
      }

      if (coinTotalRatings && coinTotalRatings.length) {
        coinTotalRatings = coinTotalRatings.sort((a, b) => {
          return a.position - b.position;
        });
      }

      return resp.data;
    },
    showEditNickname() {
      this.newNickname = this.getProfile.nickname;
      this.editNickname = true;
    },
    hideEditNickname() {
      this.editNickname = false;
    },
    updateNickname() {
      const newNickname = this.newNickname;

      if (newNickname !== this.getProfile.nickname) {
        this.USER_NICKNAME(newNickname)
          .then(() => {
            this.hideEditNickname();
          })
          .catch(this.handleError);
      } else {
        this.hideEditNickname();
      }
    },
    openQuest(task) {
      if (task.state >= 2) {
        return;
      }

      GetQuestByID({ questId: task.id }).then((resp) => {
        const questType = resp.data.questType;

        switch (questType) {
          case 0: // Kpi
            this.$modal.show("kpi", {
              data: resp.data,
              type: "kpi",
              hideClose: true,
            });
            break;
          case 1: // Test
            this.$modal.show("test", { questId: resp.data.id });
            break;
          case 2: // Question
            this.$modal.show("question", { questId: resp.data.id });
            break;
          case 3: // OffGame
            this.$modal.show("kpi", {
              data: resp.data,
              type: "offGame",
              hideClose: true,
            });
            break;
          case 4: // Business
            this.$modal.show("business", { data: resp.data, hideClose: true });
            break;
        }
      });
    },
    closeModalTask() {
      this.fetchData();
    },
    // getUsersKPI(id) {
    //   const newArray = JSON.parse(JSON.stringify(this.getKpiWeeklyRatings));
    //   const currentKPI = newArray.filter((e) => e.kpi === id);
    //   const users = currentKPI[0].positions.sort(
    //     (a, b) => a.position - b.position
    //   );

    //   return users;
    // },
    // getUsersWeeklyKPI(id) {
    //   if (this.isOtherProfile) {
    //     return this.otherUser.kpiWeeklyRatings.filter((e) => e.kpi === id)[0]
    //       ?.positions;
    //   } else {
    //     return this.getKpiWeeklyRatings.filter((e) => e.kpi === id)[0]
    //       ?.positions;
    //   }
    // },
    // getUsersTotalKPI(id) {
    //   if (this.isOtherProfile) {
    //     return this.otherUser.kpiTotalRatings.filter((e) => e.kpi === id)[0]
    //       ?.positions;
    //   } else {
    //     return this.getKpiTotalRatings.filter((e) => e.kpi === id)[0]
    //       ?.positions;
    //   }
    // },
    async getUserCoinProfile() {
      if (this.getUsersCoinsProfileStatus !== "success") {
        await this.USERS_COINS_PROFILE_FETCH();
      }

      this.$modal.show("statsRating", {
        data: {
          userId: this.user.id,
          title: "GPB-coins",
          usersCoinsWeekly: this.getUsersCoinsProfile,
          usersCoinsTotal: this.getUsersCoinsProfileOverall,
        },
      });
    },
    getUserCoinWeeklyRatings() {
      if (this.isOtherProfile) {
        return this.otherUser.coinWeeklyRatings.sort((a, b) => {
          return a.position - b.position;
        });
      } else {
        return this.getCoinWeeklyRatings;
      }
    },
    getUserCoinTotalRatings() {
      if (this.isOtherProfile) {
        return this.otherUser.coinTotalRatings.sort((a, b) => {
          return a.position - b.position;
        });
      } else {
        return this.getCoinTotalRatings;
      }
      // return JSON.parse(JSON.stringify(this.getCoinTotalRatings)).sort(
      //   (a, b) => a.position - b.position
      // );
    },
    onDeletePhoto() {
      DeleteProfilePhoto()
        .then(() => {
          this.USER_DELETE_PHOTO();
        })
        .catch(() => {
          this.$modal.show("message", {
            message: "Произошла ошибка, попробуйте позже",
          });
        });
    },
    toAchivements() {
      this.$router.push({
        name: "achievements",
        params: {
          userID: this.isOtherProfile ? this.otherUser.id : this.getProfile.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.captain {
  margin-top: 10px;
  display: grid;
  gap: 5px;
  border: 1px solid #35978c;
  box-sizing: border-box;
  // filter: drop-shadow(0px 0px 7px rgba(45, 255, 255, 0.75));
  box-shadow: 0px 0px 7px rgba(45, 255, 255, 0.5);
  border-radius: 12px;
  width: 254px;
  padding: 10px 25px;
  padding-bottom: 14px;
  &__title {
    font-size: 30px;
  }
  &__link {
    a {
      font-size: 18px;
      color: #ade5d9;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
</style>
